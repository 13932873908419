<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <c-card title="기본정보" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-if="editable"
              :url="insertUrl"
              :isSubmit="isSave"
              :param="grid.data"
              mappingType="POST"
              label="저장"
              icon="save"
              @beforeAction="saveEducation"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-plant 
              :required="true" 
              :disabled="popupParam.disabled"
              :editable="editable" 
              type="edit" 
              name="plantCd" 
              v-model="eduInfo.plantCd" />
          </div>
          <!-- <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              :required="true" 
              :editable="editable"
              label="교육년도"
              type="year"
              name="educationYear"
              v-model="eduInfo.educationYear">
            </c-datepicker>
          </div> -->
          <!-- <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              :required="true" 
              :editable="editable"
              label="교육일자"
              :range="true"
              name="educationDate"
              v-model="eduInfo.educationDate">
            </c-datepicker>
          </div> -->
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="연간교육계획 목록"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        :isExcelDown="false"
        :editable="editable"
        noDataLabel="연간교육계획을 할 교육과정을 추가하세요."
        selection="multiple"
        cardClass="topcolor-orange"
        rowKey="eduEducationId"
        @innerBtnClicked="innerBtnClicked"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline>
            <!-- <c-btn v-if="editable" :showLoading="false" label="적용" icon="check" @btnClicked="apply" /> -->
            <c-btn v-if="editable" :showLoading="false" label="교육과정추가" icon="add" @btnClicked="add" />
            <c-btn v-if="editable && grid.data.length > 0" :showLoading="false"  label="삭제" icon="remove" @btnClicked="remove" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-plan',
   components: {
  },
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduEducationId: '',
        selectData: [],
        educationYear: '',
        eduCourseId: '',
        educationMonth: '',
        plantCd: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      eduInfo: {
        plantCd: null,
        educationDSate: '',
      },
      applyInfo: {
        educationMonth: null,
        mainTargetAudience: '',
        educationLocation: '',
        educationMethodCd: null,
      },
      grid: {
        columns: [],
        data: [],
        height: '500px'
      },
      title: '',

      getUrl: '',
      insertUrl: '',
      updateUrl: '',
      completeUrl: '',
      deleteUrl: '',

      isSave: false,
      updateMode: false,

      classCdItems: [],
      methodCdItems: [],
      monthItems: [],
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },

      row: null,
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.insertUrl = transactionConfig.sop.edu.annual.insert.url;
      this.deleteUrl = transactionConfig.sop.edu.annual.delete.url;
      this.completeUrl = transactionConfig.sop.min.equipment.plan.complete.url;
      this.getUrl = selectConfig.sop.edu.annual.get.url;
      // list setting
      if (this.popupParam.eduCourseId) {
        this.eduInfo.plantCd = this.popupParam.plantCd
        this.eduInfo.educationYear = this.popupParam.educationYear
      }
      if (this.popupParam.selectData && this.popupParam.selectData.length > 0) {
        this.$_.forEach(this.popupParam.selectData, _item => {
          this.$http.url = this.$format(selectConfig.sop.edu.course.get.url
          , _item.eduCourseId);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            this.grid.data.splice(0, 0, {
            educationCourseName: _item.educationCourseName, // 교육과정명
            educationTypeCd: _item.educationTypeCd, // 교육구분
            educationName: _item.educationName, // 교육명
            eduEducationId: uuidv4(),
            educationMonth: null,
            educationLocation: '',
            educationMethodCd: null,
            editFlag: 'C',

            // 교육과정 마스터 데이터 
            eduCourseId: _result.data.eduCourseId, // 교육과정 key
            educationCycle: _result.data.educationCycle, // 교육주기
            educationKindCdLarge: _result.data.educationKindCdLarge, // 교육종류 대
            educationKindCdSmall: _result.data.educationKindCdSmall, // 교육종류 소
            educationPurpose: _result.data.educationPurpose, // 학습목적
            legalEducationFlag: _result.data.legalEducationFlag, // 법정여부
            mainTargetAudience: _result.data.mainTargetAudience, // 주요대상자
            relationLaws: _result.data.relationLaws, // 관련법규
            educationTime: _result.data.educationTime, // 교육시간
            estimatedEducationExpenses: _result.data.estimatedEducationExpenses // 교육시간
          })
        },);
        })
      }
      this.getList();
      this.setHeader();
    },
    setHeader() {
      this.$comm.getComboItems('EDU_CLASS_CD').then(_result => {
        this.$comm.getComboItems('EDU_METHOD_CD').then(_result2 => {
            this.grid.columns = [
            {
              name: 'educationCourseName',
              field: 'educationCourseName',
              required: true,
              innerBtn: true,
              btns: [
                { label: '', icon: 'add', color: 'orange', tooltip: '교육과정 추가' },
              ],
              label: '교육과정',
              align: 'center',
              style: 'width:120px', 
              sortable: false,
              // rowspan: 5,
            },
            {
              name: 'educationName',
              field: 'educationName',
              required: true,
              label: '교육명',
              align: 'left',
              type: 'text',
              style: 'width:200px', 
              sortable: false,
              // rowspan: 5,
            },
            {
              name: 'educationTypeCd',
              field: 'educationTypeCd',
              setHeader: true,
              required: true,
              label: '교육구분',
              align: 'center',
              style: 'width:100px',
              type: 'select',
              comboItems: _result,
              sortable: false,
            },
            {
              name: 'mainTargetAudience',
              field: 'mainTargetAudience',
              required: true,
              label: '주요 대상자',
              type: 'text',
              style: 'width:150px',
              align: 'left',
              sortable: false,
            },
            {
              name: 'educationDate',
              field: 'educationDate',
              required: true,
              label: '교육기간',
              range: true,
              minuteStep: 10,
              type: 'date',
              style: 'width:150px',
              align: 'left',
              sortable: false,
            },
            {
              name: 'educationMethodCd',
              field: 'educationMethodCd',
              setHeader: true,
              required: true,
              label: '교육방법',
              align: 'center',
              style: 'width:100px',
              type: 'select',
              comboItems: _result2,
              sortable: false,
            },
            {
              name: 'educationLocation',
              field: 'educationLocation',
              required: true,
              label: '교육장소',
              type: 'text',
              style: 'width:100px',
              align: 'left',
              sortable: false,
            },
          ]
        });
      });
    },
    getList() {
     if (this.popupParam.eduCourseId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.eduCourseId, this.popupParam.educationYear, this.popupParam.educationMonth);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
          this.$_.forEach(this.grid.data, _item => {
            _item.educationDate = [_item.educationStartDate, _item.educationEndDate]
          })
          this.updateMode = true;
        },);
      }
    },
    add() {
      this.popupOptions.title = '교육과정 검색'; // 교육과정 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'./educationCurriculumnPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEduCoursePopup;
    },
    closeEduCoursePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.selectData && data.selectData.length > 0) {
        this.$_.forEach(data.selectData, _item => {
          let index = this.$_.findIndex(this.grid.data, {
            eduCourseId: _item.eduCourseId,
          });
          if (index === -1) {
            this.grid.data.splice(0, 0, {
              educationCourseName: _item.educationCourseName, // 교육과정명
              educationTypeCd: _item.educationTypeCd, // 교육구분
              eduEducationId: uuidv4(),
              educationMonth: null,
              educationLocation: '',
              educationMethodCd: null,
              educationName: '', 
              editFlag: 'C',

              // 교육과정 마스터 데이터 
              eduCourseId: _item.eduCourseId, // 교육과정 key
              educationCycle: _item.educationCycle, // 교육주기
              educationKindCdLarge: _item.educationKindCdLarge, // 교육종류 대
              educationKindCdSmall: _item.educationKindCdSmall, // 교육종류 소
              educationPurpose: _item.educationPurpose, // 학습목적
              legalEducationFlag: _item.legalEducationFlag, // 법정여부
              mainTargetAudience: _item.mainTargetAudience, // 주요대상자
              relationLaws: _item.relationLaws, // 관련법규
              educationTime: _item.educationTime, // 교육시간
              estimatedEducationExpenses: _item.estimatedEducationExpenses // 교육시간
            })
          }
        })
      }
    },
    saveEducation() {
      let isConti = true;
      let checkItem = ['educationDate', 'educationCourseName', 'educationName', 'educationTypeCd', 'mainTargetAudience', 'educationLocation', 'educationMethodCd' ]
      if (!this.eduInfo.plantCd) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '공사현장을 선택하세요.', // 공사현장을 선택하세요
          type: 'warning', // success / info / warning / error
        });
        isConti = false;
        return;
      }
      this.$_.forEach(this.grid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      }); 
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [교육과정, 교육명, 교육구분, 주요 대상자, 교육일자, 교육장소, 교육방법]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, _item => {
              _item.plantCd = this.eduInfo.plantCd,
              _item.educationStartDate = _item.educationDate[0],
              _item.educationEndDate = _item.educationDate[1],
              _item.regUserId = this.$store.getters.user.userId
              _item.chgUserId = this.$store.getters.user.userId
            })
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      this.emit('SAVE');
    },
    emit(result) {
      if (result === 'COMPLETE') {
        this.$emit('closePopup', result);
      } else if (result === 'SAVE') {
        this.$emit('closePopup', result);
      } 
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      console.log(selectData)
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request((_result) => {
            console.log(_result)
            this.$_.forEach(selectData, item => {
              this.grid.data = this.$_.reject(this.grid.data, { eduEducationId: item.eduEducationId })
          })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    apply() {
      console.log()
    },
    innerBtnClicked(col, props) {
      this.grid.data.splice(props.rowIndex, 0, {
        eduEducationId: uuidv4(),
        key: props.row.key, 
        educationCourseName: props.row.educationCourseName, 
        educationTypeCd: props.row.educationTypeCd,
        educationMonth: null,
        educationLocation: props.row.educationLocation,
        educationMethodCd: props.row.educationMethodCd,
        editFlag: 'C',
        eduCourseId: props.row.eduCourseId, // 교육과정 key
        educationCycle: props.row.educationCycle, // 교육주기
        educationKindCdLarge: props.row.educationKindCdLarge, // 교육종류 대
        educationKindCdSmall: props.row.educationKindCdSmall, // 교육종류 소
        educationPurpose: props.row.educationPurpose, // 학습목적
        legalEducationFlag: props.row.legalEducationFlag, // 법정여부
        mainTargetAudience: props.row.mainTargetAudience, // 주요대상자
        relationLaws: props.row.relationLaws, // 관련법규
        educationTime: props.row.educationTime, // 교육시간
        estimatedEducationExpenses: props.row.estimatedEducationExpenses // 교육시간
      });
    },
  }
};
</script>